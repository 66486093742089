<template>
  <div class="mitraSearch">
    <navbar-home-component class="mitra" />
    <section class="headerSearch">
      <div class="container">
        <div class="contentSearch">
          <div class="img">
            <img src="@/assets/img/store3.png" alt="" />
          </div>
          <div class="formSearch subMitra">
            <p class="titleNameMitra">{{ mitra.name }}</p>
            <div class="contentTitle">
              <p class="titleSearch">
                Temukan Sparepart atau Tools yang Anda Cari.
              </p>
            </div>
            <p class="text-danger" v-if="errorMessage">{{ errorMessage }}</p>

            <div class="div">
              <div class="searchbar">
                <div class="searchbar-wrapper">
                  <div class="searchbar-left">
                    <div class="search-icon-wrapper">
                      <span class="search-icon searchbar-icon">
                        <font-awesome-icon
                          :icon="['fas', 'magnifying-glass']"
                          size="lg"
                        />
                      </span>
                    </div>
                  </div>
                  <div class="searchbar-center">
                    <div class="searchbar-input-spacer"></div>
                    <input
                      ref="searchInput"
                      type="text"
                      class="searchbar-input"
                      autocomplete="off"
                      @keyup.enter="cariProduk"
                      v-model="searchProduct"
                      title="Search"
                      placeholder="Ketik Nama Produk/Tipe Handphone"
                    />
                  </div>
                  <div class="searchbar-right">
                    <button
                      @click="cariProduk"
                      class="btn btn-primary btn-search"
                    >
                      Cari
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-show-small">
              <button @click="cariProduk" class="btn btn-primary btn-search">
                Cari
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="resultSearch" ref="resultSection">
      <div class="container">
        <transition name="fade">
          <div class="contentResult" v-if="dataShow">
            <div class="row justify-content-center">
              <div class="col-md-7">
                <p class="titleSearch">
                  Showing results for :
                  <span class="keySearch">{{ keySearch }}</span> -
                  {{ totalItems }} Products found
                </p>
                <div
                  v-if="filteredProducts.length"
                  class="contentTable table-responsive"
                >
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <td>Product</td>
                        <td>Stock</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="product in filteredProducts"
                        :key="product.Product"
                      >
                        <td>{{ product.product }}</td>
                        <td>
                          <span
                            v-if="product.stock !== '0'"
                            class="badge bg-primary"
                            >{{ product.stock }}</span
                          >
                          <span v-else class="badge bg-danger">{{
                            product.stock
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="pagination mt-2">
                    <span
                      >Page
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        v-model.number="inputPage"
                        @change="onPageInputChange"
                        :min="1"
                        :max="totalPages"
                      />
                      of {{ totalPages }}</span
                    >
                    <div>
                      <button
                        class="btn prevPage"
                        v-if="currentPage > 1"
                        @click="changePage(currentPage - 1)"
                      >
                        <font-awesome-icon :icon="['fas', 'arrow-left-long']" />
                      </button>
                      <button
                        v-if="currentPage < totalPages"
                        class="btn nextPage"
                        @click="changePage(currentPage + 1)"
                      >
                        Next
                        <font-awesome-icon
                          :icon="['fas', 'arrow-right-long']"
                          class="ms-2"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="not-found" v-else>
                  <div class="row justify-content-center mt-3">
                    <div class="col-md-6">
                      <img src="@/assets/img/not-found.png" alt="" />
                      <p class="titleNotfound">Oops.. No Result Found</p>
                      <p class="descNotFound">
                        Dont Worry, it happens sometimes. Perhaps you could try
                        entering a different search term.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="btnClear">
              <button class="btn btn-primary" @click="clearSearch">
                Clear Search
              </button>
            </div>
          </div>
          <div class="imgDefaultSearch" v-else>
            <img src="@/assets/img/searchMitra.png" alt="" />
            <div class="row justify-content-center">
              <div class="col-md-5">
                <p class="content-text">
                  Gunakan pencarian untuk menemukan produk yang Anda cari di
                  <span
                    >Mitra Resmi
                    <router-link to="/">GadgetShop</router-link> .</span
                  >
                </p>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </section>
  </div>
</template>

<script>
import apis from "@/apis";
import NavbarHomeComponent from "@/components/NavbarHomeComponent.vue";
export default {
  components: { NavbarHomeComponent },
  metaInfo() {
    return {
      title: "Mitra Gadgetshop",
    };
  },
  data() {
    return {
      mitra: "",
      searchProduct: "",
      products: [],
      filteredProducts: [],
      dataShow: false,
      currentPage: 1,
      inputPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0,
      keySearch: "",
      merchantname: "",
      errorMessage: "",
    };
  },

  async created() {
    this.merchantname = this.$route.params.nameStoreMitra;
    try {
      const postData = {
        merchantname: this.merchantname,
      };
      const response = await apis.apis.post(
        apis.PublicPath + "/merchantIdentity",
        postData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      this.mitra = response.data;
    } catch (error) {
      this.$router.push("/mitra");
      // console.error("Error fetching data:", error);
    }
  },

  methods: {
    async cariProduk() {
      const query = this.searchProduct.trim();
      this.currentPage = 1;
      this.itemsPerPage = 0;
      this.inputPage = 1;
      if (!query) {
        this.clearSearch();
        return;
      }
      if (query.length < 3) {
        this.errorMessage = "Search term must be at least 3 characters!";
        return;
      }
      this.searchQuery();
    },

    async searchQuery() {
      const query = this.searchProduct.trim();
      let currentPage = (this.currentPage - 1) * this.itemsPerPage;
      try {
        const postData = {
          produk: query,
          start: currentPage,
          merchantname: this.merchantname,
        };
        const { data } = await apis.apis.post(
          apis.PublicPath + "/merchantProducts",
          postData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        this.keySearch = query;
        this.itemsPerPage = data.item_per_page;
        this.errorMessage = "";
        this.updateSearchResults(data);
      } catch (error) {
        // console.error("Error fetching products:", error);
      }
    },

    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = this.inputPage = page;
        this.searchQuery();
      }
    },
    onPageInputChange() {
      const page = parseInt(this.inputPage);
      if (page >= 1 && page <= this.totalPages) this.changePage(page);
      else this.inputPage = this.currentPage;
    },
    clearSearch() {
      this.searchProduct = "";
      this.filteredProducts = [];
      this.dataShow = false;
    },

    updateSearchResults(data) {
      this.filteredProducts = data.results.map((item) => ({
        product: item.nama_produk,
        stock: item.stock,
      }));
      this.totalItems = data.total_records;
      this.totalPages = data.total_pages;
      this.dataShow = true;
      this.$nextTick(() =>
        this.$refs.resultSection.scrollIntoView({ behavior: "smooth" })
      );
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>