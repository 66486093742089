import Vue from "vue";
import VueRouter from "vue-router";
import NotFoundView from "../views/NotFoundView.vue";
import SearchProductView from "../views/SearchProductView.vue";
import MitraProductView from "../views/MitraProductView.vue";
import MitraView from "../views/MitraView.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Search",
    component: SearchProductView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mitra/:nameStoreMitra",
    name: "MitraDetail",
    component: MitraProductView,
  },
  {
    path: "/mitra",
    name: "Mitra",
    component: MitraView,
  },

  {
    path: "*",
    name: "NotFound",
    component: NotFoundView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
