<template>
  <div class="sticky-top">
    <nav class="navbar navbar-expand-lg">
      <div class="container">
        <div class="navbar-brand">
          <img src="@/assets/img/logo.png" v-if="!isMenuRoute" alt="" />
          <img :src="`${BASE_URL}mitra/logo/${mitra.logo}`" v-else alt="" />
        </div>
        <button
          v-if="!isMenuRoute"
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent1"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="isToggled = !isToggled"
          :class="{ active: isToggled }"
        >
          <font-awesome-icon
            :icon="['fa-solid', isToggled ? 'fa-xmark' : 'fa-align-right']"
          />
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent1">
          <ul
            class="navbar-nav navbar-hide ms-auto mb-lg-0"
            v-if="!isMenuRoute"
          >
            <li class="nav-item">
              <router-link class="nav-link" to="/"
                ><font-awesome-icon
                  icon="fa-solid fa-house"
                  class="me-1"
                />Home</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/mitra"
                ><font-awesome-icon
                  :icon="['fas', 'store']"
                  class="me-1"
                />Mitra Kami</router-link
              >
            </li>
          </ul>

          <!-- navbar mobile toggle -->
          <ul class="navbar-nav ms-auto mb-lg-0 navbar-show">
            <li class="nav-item">
              <router-link class="nav-link" to="/mitra"
                ><font-awesome-icon
                  :icon="['fas', 'store']"
                  class="me-1"
                />Mitra Kami</router-link
              >
            </li>
          </ul>
          <!-- akhir navbar mobile toggle  -->
        </div>
      </div>
    </nav>
    <!-- akhir navbar -->
  </div>
</template>

<script>
import { BASE_URL } from "@/config";
// import Swal from "sweetalert2";
// import { EventBus } from "@/event-bus.js";
// import router from "@/router";
import apis from "@/apis";
export default {
  name: "NavbarComponent",
  data() {
    return {
      isToggled: false,
      mitra: "",
      BASE_URL,
    };
  },
  computed: {
    isMenuRoute() {
      return this.$route.name === "MitraDetail";
    },
  },
  mounted() {
    // Panggil getData jika route name adalah MitraDetail
    if (this.isMenuRoute) {
      this.getData();
    }
  },
  methods: {
    toggleToggler() {
      this.isToggled = !this.isToggled;
    },
    async getData() {
      this.merchantname = this.$route.params.nameStoreMitra;
      try {
        const postData = {
          merchantname: this.merchantname,
        };
        const response = await apis.apis.post(
          apis.PublicPath + "/merchantIdentity",
          postData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        this.mitra = response.data;
      } catch (error) {
        this.$router.push("/mitra");
        // console.error("Error fetching data:", error);
      }
    },
  },
};
</script>

