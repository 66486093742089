<template>
  <footer class="mt-auto">
    <section class="footer">
      <div class="container">
        <div class="cta">
          <div class="img"></div>
          <div class="cr">
            <p class="footerAddress">
              <font-awesome-icon :icon="['fas', 'location-dot']" class="me-2" />
              {{ address }}
            </p>
            <p>
              Copyright © 2023
              <router-link class="link-footer" to="/">GadgetShop</router-link>
              All rights reserved.
            </p>
          </div>
        </div>
        <div class="popup-wa">
          <a
            v-b-tooltip.hover="'Hubungi Admin '"
            :href="`https://api.whatsapp.com/send?phone=62${phoneNumber}&text=Halo+Admin+saya+ingin+bertanya`"
            class="float"
            target="_blank"
          >
            <font-awesome-icon icon="fa-brands fa-whatsapp " class="my-float" />
          </a>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
import apis from "@/apis";
export default {
  name: "FooterComponent",
  data() {
    return {
      sosmed: "",
      phoneNumber: "",
      address: "",
    };
  },

  async mounted() {
    this.checkRouteAndFetchData();
  },
  watch: {
    $route() {
      this.checkRouteAndFetchData();
    },
  },
  methods: {
    checkRouteAndFetchData() {
      if (this.$route.name === "MitraDetail") {
        this.getDataMitra();
      } else {
        this.getDataGadgetshop();
      }
    },
    async getDataGadgetshop() {
      try {
        const response = await apis.apis.post(apis.PublicPath + "/gadgetshop");
        this.phoneNumber = response.data.whatsapp;
        this.address = response.data.alamat_store;
      } catch (error) {
        // console.error(error);
      }
    },
    async getDataMitra() {
      this.merchantname = this.$route.params.nameStoreMitra;
      try {
        const postData = {
          merchantname: this.merchantname,
        };
        const response = await apis.apis.post(
          apis.PublicPath + "/merchantIdentity",
          postData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        this.phoneNumber = response.data.phone;
        this.address = response.data.address;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
};
</script>
<style></style>
